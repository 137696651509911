<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
      <div style="height:40vh;" class="my-5 py-5">
<h1 class="text-center"> Page Not Found</h1>
      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>


export default {
   components:{
   },
    name: "404",
}
</script>

<style>

</style>